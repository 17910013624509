var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showComponent)?_c('div',{staticClass:"blog-container "},[_c('div',{staticClass:"tw-w-full lg:tw-w-auto"},[_c('div',{staticClass:"tw-w-full"},[_c('div',{staticClass:"tw-flex header tw-mb-10"},[_c('div',{staticClass:"tw-cursor-pointer",on:{"click":_vm.routeBack}},[_c('Icons',{staticClass:"tw-mr-3 arrow",attrs:{"name":"return-arrow"}})],1),_c('h2',[_vm._v("Create Blog Post")])]),_c('div',{staticClass:"form-body tw-px-5 tw-py-10"},[_c('h3',{staticClass:"post-text"},[_vm._v("Upload Image")]),_c('validation-provider',{attrs:{"name":"PostImage","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-flex tw-justify-center tw-flex-col"},[_c('input',{staticClass:"image-input",attrs:{"type":"file","id":"file"},on:{"change":_vm.handleImageUpload}}),_c('label',{staticClass:"image-label",attrs:{"for":"file"}},[(_vm.imageUrl)?_c('img',{staticClass:"image",attrs:{"src":_vm.imageUrl,"alt":""}}):_c('img',{attrs:{"src":require("../../../assets/ion_image-outline.png"),"alt":""}})]),_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,false,519292760)}),_c('validation-provider',{attrs:{"name":"Post Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"post-text"},[_vm._v("Title")]),_c('v-text-field',{staticClass:"post-title-input",attrs:{"type":"text","placeholder":"Title","solo":"","hide-details":""},model:{value:(_vm.blogData.postTitle),callback:function ($$v) {_vm.$set(_vm.blogData, "postTitle", $$v)},expression:"blogData.postTitle"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,false,1476828908)}),_c('validation-provider',{attrs:{"name":"Medium Link","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"post-text"},[_vm._v("Medium Link")]),_c('v-text-field',{staticClass:"post-title-input",attrs:{"type":"url","placeholder":"https://medium.com/nomadicpod","solo":"","hide-details":""},model:{value:(_vm.blogData.mediumLink),callback:function ($$v) {_vm.$set(_vm.blogData, "mediumLink", $$v)},expression:"blogData.mediumLink"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,false,2707376726)}),_c('validation-provider',{attrs:{"name":"Post Content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"post-text"},[_vm._v("Description")]),_c('v-textarea',{staticClass:"post-label",attrs:{"type":"text","placeholder":"Write something great","solo":"","hide-details":""},model:{value:(_vm.blogData.postContent),callback:function ($$v) {_vm.$set(_vm.blogData, "postContent", $$v)},expression:"blogData.postContent"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,false,3692470102)}),_c('validation-provider',{attrs:{"name":"WrittenBy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"post-text"},[_vm._v("Written by")]),_c('v-text-field',{staticClass:"post-title-input",attrs:{"type":"text","placeholder":"Written by","solo":"","hide-details":""},model:{value:(_vm.blogData.writtenBy),callback:function ($$v) {_vm.$set(_vm.blogData, "writtenBy", $$v)},expression:"blogData.writtenBy"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,false,737092360)}),_c('h3',{staticClass:"post-text"},[_vm._v("Tag")]),_c('v-text-field',{staticClass:"post-title-input",attrs:{"type":"text","placeholder":"Type a tag or choose from below","solo":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addChip.apply(null, arguments)}},model:{value:(_vm.tagInput),callback:function ($$v) {_vm.tagInput=$$v},expression:"tagInput"}}),[_c('div',{staticClass:"chip-container"},_vm._l((_vm.chips),function(chip){return _c('v-chip',{key:chip.tagName,staticClass:"ma-2",attrs:{"color":chip.color,"id":"chip","close":""},on:{"click:close":function($event){return _vm.removeTag(chip)}}},[_vm._v(" "+_vm._s(chip.tagName)+" ")])}),1)],_c('div',{staticClass:"tw-flex tw-w-full tw-items-end tw-justify-end tw-mt-10"},[_c('v-btn',{staticClass:"post-button",attrs:{"width":_vm.$vuetify.breakpoint.smAndDown ? '100%': '',"loading":_vm.loading},on:{"click":_vm.toggleCreateOrUpdate}},[_vm._v("Post")])],1)],2)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }