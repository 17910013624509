<script>
import {createBlogPost, updateBlogPost, uploadToCloudinary} from "@/services/api/APIService";
import {mapGetters} from "vuex";
import Icons from "@/components/reuseables/Icons.vue";
export default {
  name: "AddBlogPostView",
  components: {Icons},
  data() {
    return {
      tagInput: '',
      chips: [],
      imageUrl: null,
      loading : false,
      blogData:{
        postTitle: '',
        postDescription:'',
        postContent:'',
        postImage: '',
        writtenBy: '',
      },
      showComponent: true
    };
  },
 computed:{
   preset() {
     return process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET
   },
   ...mapGetters("marketing",["selectedBlogPost"])
 },
  watch:{
    '$route.name':{
      handler : function (val) {
        if( val === "EditBlogPost"){
          this.blogData = this.selectedBlogPost
          this.imageUrl = this.selectedBlogPost.postImage
          this.selectedBlogPost.tags.forEach(tag => {
            this.chips.push({tagName : tag.tagName, color: this.getRandomColor()})
          })
        }
        else {
          this.blogData = {}
        }
      },
      immediate: true
    }
  },
  methods: {
   async createPost(){
     this.loading = true
      let newData = {
        file: this.imageUrl,
        upload_preset: this.preset
      }
     await uploadToCloudinary(newData).then( res => {
       this.blogData.postImage = res.data.url
       createBlogPost(this.blogData).then(() =>{
         this.$displaySnackbar({
           message: "Post created successfully!",
           success: true
         })
         this.loading = false
         this.$router.push({name: 'AllBlogPost'})
       }).catch(err =>{
         this.$displaySnackbar({
           message: err.message,
           success: false,
         })
     })
        this.loading = false
        console.log("Not successful")
      }).finally(()=> this.loading=false)

    },
    async updatePost(){
      this.loading = true
      if (this.blogData.postImage !== this.imageUrl){
        let newData = {
          file: this.imageUrl,
          upload_preset: this.preset
        }
        await uploadToCloudinary(newData).then( res => {
          this.blogData.postImage = res.data.url
          updateBlogPost(this.blogData).then(() =>{
            this.$displaySnackbar({
              message: "Post updated successfully!",
              success: true
            })
            this.loading = false
            this.$router.push({name: 'AllBlogPost'})
          }).catch(err =>{
            this.$displaySnackbar({
              message: err.message,
              success: false,
            })
          })
          this.loading = false
          console.log("Not successful")
        }).finally(()=> this.loading=false)
      }
      else {
        updateBlogPost(this.blogData).then(() =>{
          this.$displaySnackbar({
            message: "Post updated successfully!",
            success: true
          })
          this.loading = false
          this.$router.push({name: 'AllBlogPost'})
        }).catch(err =>{
          this.$displaySnackbar({
            message: err.message,
            success: false,
          })
        }).finally(()=> this.loading=false)
      }
    },
    toggleCreateOrUpdate(){
     if (this.$route.params.title){
       this.updatePost()
     }
     else {
       this.createPost()
     }
    },

  addChip() {
      if (this.tagInput) {
        this.chips.push({ tagName: this.tagInput, color: this.getRandomColor() });
        this.tagInput = '';
        this.blogData.tags = this.chips
        console.log(this.blogData.tags)
      }
    },
    removeTag(chip){
     this.chips = this.chips.filter(tag => tag.tagName !== chip.tagName)
      console.log(this.chips, chip)
      this.blogData.tags = this.chips
    },
    getRandomColor() {
      const colors = ['blue', 'green', 'red'];
      const randomIndex = Math.floor(Math.random() * colors.length);
      return colors[randomIndex];
    },
      handleImageUpload(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      },
    handleShowComponent(){
      this.showComponent = false
      this.$nextTick(() => {
        this.showComponent = true
      })
    },
    routeBack(){
     this.$store.dispatch("marketing/setSelectedBlogPost",{})
     this.$router.push({name: 'AllBlogPost'})
    }
  },
  created() {

  }
};
</script>

<template>
<div class="blog-container "  v-if="showComponent">
<div class="tw-w-full lg:tw-w-auto">

<div class="tw-w-full">
  <div class="tw-flex header tw-mb-10">
    <div class="tw-cursor-pointer" @click="routeBack">
      <Icons name="return-arrow" class="tw-mr-3 arrow"/>
    </div>
    <h2>Create Blog Post</h2>
  </div>

  <div class="form-body tw-px-5 tw-py-10">
    <h3 class="post-text">Upload Image</h3>
    <validation-provider name="PostImage" rules="" v-slot="{ classes, errors }">
    <div class="tw-flex tw-justify-center tw-flex-col">
      <input type="file" class="image-input" id="file" @change="handleImageUpload"/>
      <label class="image-label" for="file"  >
        <img v-if="imageUrl" :src="imageUrl" alt="" class="image"/>
        <img v-else src="../../../assets/ion_image-outline.png" alt="">
      </label>
      <span>{{ errors[0] }}</span>
    </div>
    </validation-provider>

    <validation-provider name="Post Title" rules="required" v-slot="{ classes, errors }">
    <h3 class="post-text">Title</h3>
    <v-text-field type="text" class="post-title-input" placeholder="Title" solo hide-details v-model="blogData.postTitle"/>
      <span>{{errors[0]}}</span>
      </validation-provider>

    <validation-provider name="Medium Link" rules="" v-slot="{ classes, errors }">
      <h3 class="post-text">Medium Link</h3>
      <v-text-field type="url" class="post-title-input" placeholder="https://medium.com/nomadicpod" solo hide-details v-model="blogData.mediumLink"/>
      <span>{{errors[0]}}</span>
    </validation-provider>

<!--     <ValidationProvider name="PostTitle" rules="required" v-slot="{ classes, errors }">-->
<!--    <h3 class="post-text" >Post Title</h3>-->
<!--    <v-text-field type="text" class="post-title-input" placeholder="Zoe" solo hide-details v-model="blogData.postTitle" />-->
<!--       <span>{{errors[0]}}</span>-->
<!--       </ValidationProvider>-->

       <validation-provider name="Post Content" rules="required" v-slot="{ classes, errors }">
    <h3 class="post-text">Description</h3>
    <v-textarea class="post-label" type="text" placeholder="Write something great" solo hide-details v-model="blogData.postContent"/>
         <span>{{errors[0]}}</span>
       </validation-provider>

       <validation-provider name="WrittenBy" rules="required" v-slot="{ classes, errors }">
    <h3 class="post-text">Written by</h3>
    <v-text-field type="text" class="post-title-input" placeholder="Written by" solo hide-details v-model="blogData.writtenBy"></v-text-field>
         <span>{{errors[0]}}</span>
       </validation-provider>

    <h3 class="post-text">Tag</h3>
    <v-text-field  v-model="tagInput"  @keydown.enter="addChip"
                   type="text" class="post-title-input"
                   placeholder="Type a tag or choose from below" solo hide-details/>


    <template>

      <div class="chip-container">
        <v-chip @click:close="removeTag(chip)"
            class="ma-2"
            v-for="chip in chips"
            :key="chip.tagName"
            :color="chip.color"
            id="chip"
            close
        >
          {{ chip.tagName }}
        </v-chip>
      </div>

    </template>
    <div class="tw-flex tw-w-full tw-items-end tw-justify-end tw-mt-10">
      <v-btn :width="$vuetify.breakpoint.smAndDown ? '100%': ''"  class="post-button" @click="toggleCreateOrUpdate" :loading="loading">Post</v-btn>
    </div>

  </div>
</div>
  </div>
</div>


</template>

<style scoped lang="scss">

.arrow{
  @media screen and (max-width: 375px){
    margin-right: 20px;
    margin-left: 10px;
  }
}
.blog-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header h2{
  font-family: Inter sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #263238;

  @media screen and (max-width: 375px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }
}
.form-body{
  width: 612px;
  min-height: max-content;
  background: #FEFFFF;
  box-shadow: 0 2px 20px 0 #00000008;
  @media screen and (max-width: 820px){
    width: 100%;
    min-height: max-content;
    border-radius: 10px;
    margin-top: 50px;
  }
}


.image-label .image {
  width: 100%;
  height: 192px;
  border-radius: 10px;
  object-fit: contain;
}

.image-label .image[src] {
  max-width: 100%;
  max-height: 300px;
  display: block;
  margin: auto;
  border-radius: 10px;
  object-fit: fill;
  object-position: top;
}

.image-input{
  display: none;
}
.image-label{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 192px;
  border-radius: 10px;
  border: 1px dashed #004aad;
  @media screen and (max-width: 375px){
    height: 258px;
    border-radius: 10px;
    border: 1px dashed #004aad;
    margin-bottom: 40px;

  }
}
.post-title-input{

}

.post-text{
  color: #4F4F4F;
  font-family: Inter,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 1rem;
  margin-top: 1rem;
}
.image{
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  @media screen and (max-width: 375px) {
    margin-top: 40%;
    margin-left: 40%;
  }
}
#post-title-text{
  padding: 0;
  margin-top: 20px;
  @media screen and (max-width: 375px){
  margin-top: 0;
  }
}
::placeholder{
  color:#c4c4c4;
  padding-left: 25px;
  font-family: Inter sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
}


.post-label{

}
.post-label::placeholder{
  margin-left: 30px;
}
.post-button{
  background: #004AAD !important;
  font-family: Inter sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  color: #FFFFFF;
  text-transform: capitalize;
}

.post-button-mobile{
    display: flex;
    width: 258px;
    height: 48px;
    border-radius: 10px;
    //background: #004AAD;
    color: #FFFFFF;
    font-family: Inter sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
}
#chip{
  border-radius: 10px;
  text-align: center;
  font-family: Inter sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #F6F7F9;
}
.chip-container{
  @media screen and (max-width: 375px){
    padding-left: 13px;
  }
}

span{
  color: red;
  font-size: 14px;
  margin-top: 5px;
  //margin-left: 20px;
}

</style>


